<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconYoutube',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="youtube">
      <path
        :fill="color"
        d="M171.5,63.9c-1.7-6.5-6.8-11.6-13.2-13.3c-11.6-3.1-58.3-3.1-58.3-3.1s-46.7,0-58.3,3.1
c-6.4,1.7-11.5,6.8-13.2,13.3c-3.1,11.7-3.1,36.1-3.1,36.1s0,24.4,3.1,36.1c1.7,6.5,6.8,11.6,13.2,13.3c11.6,3.1,58.3,3.1,58.3,3.1
s46.7,0,58.3-3.1c6.4-1.7,11.5-6.8,13.2-13.3c3.1-11.7,3.1-36.1,3.1-36.1S174.6,75.6,171.5,63.9z M84.7,122.2V77.8l39,22.2
L84.7,122.2z"
      />
    </g>
  </svg>
</template>
